<template lang="pug">
  .top-bar.container-fluid
    .row.m-b-18
      .col-sm-5.col-xs-12.app-search-wrapper
        AppSearch(
          :placeholder="$t('components.app_search.free_word_search')"
          @update="$emit('search', { search_value: $event })"
        )
      .ml-auto.m-t-4
        AppSaveButton(
          v-if="hasEditPermission()" 
          short
          :disabled="!allowSave"
          title="actions.save_changes"
          @save="$emit('save')"
        )
</template>

<script>
  import withPermissions from "@/mixins/withPermissions"

  export default {
    props: {
      allowSave: {
        type: Boolean,
        default: true
      }
    },
    components: {
      AppSaveButton: () => import("@/components/elements/AppButton/Save"),
      AppSearch: () => import("@/components/elements/AppSearch")
    },

    mixins: [withPermissions]
  }
</script>

<style lang="sass" scoped>
  @import "@/assets/styles/variables.sass"

  .top-bar
    height: auto
    margin-bottom: $topbar-margin-bottom
    padding-top: 20px

  .app-search-wrapper
    padding-left: 0
</style>
